import React from "react";
import { graphql } from "gatsby";

import { Layout } from "~components";
import { Img, RawHtml } from "~utils";

/**
 * @typedef {{
 *  data: GatsbyTypes.PageQuery,
 *  pageContext: GatsbyTypes.SitePageContext,
 * }} Props
 *
 * @extends {React.Component<Props>}
 */
export default class Page extends React.Component {
  render() {
    const { data, pageContext } = this.props;
    const { frontmatter } = data.page;
    const image = frontmatter.img_path?.childImageSharp?.fluid;

    return (
      <Layout pageData={data}>
        <article className="post page post-full">
          <header className="post-header">
            <h1 className="post-title underline">{frontmatter.title}</h1>
          </header>
          {frontmatter.subtitle && (
            <div className="post-subtitle">
              {<RawHtml html={frontmatter.subtitle} />}
            </div>
          )}
          {frontmatter.show_img && image && (
            <div className="post-thumbnail">
              <Img fluid={image} alt={frontmatter.title} />
            </div>
          )}
          <div className="post-content">
            {<RawHtml html={pageContext.html} />}
          </div>
        </article>
      </Layout>
    );
  }
}

export const query = graphql`
  query Page($url: String!, $headerImg: String) {
    ...PageData
    page: markdownRemark(fields: { url: { eq: $url } }) {
      frontmatter {
        show_img
        img_path {
          childImageSharp {
            fluid(maxWidth: 600, quality: 80) {
              ...FluidImg
            }
          }
        }
      }
    }
  }
`;
